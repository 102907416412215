.footer {
    width: 100%;
    background-color: rgba(0, 0, 0, 1);
    color: bisque;
}

.footer-container {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    padding-bottom: 1rem;
}

.left {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 4rem;
}

.Phone {
    display: flex;
}

.location {
    display: flex;
    margin-bottom: 0.3rem;
    padding-top: 1.2rem;
}

.email {
    display: flex;
    margin-bottom: 0.7rem;
}

.right {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    padding-right: 4rem;
    row-gap: 2rem;
}


h4 {
    font-size: 1.1rem;
    padding-bottom: 0.4rem;
    color: bisque;
}

h3 {
    font-size: 1.4rem;
    padding-bottom: 0.1rem;
}

p {
    font-size: 1.1rem;
}

.rights-reserved {
    padding-bottom: 1.5rem;
    padding-top: 1rem;
}

.rights-reserved p {
    color: bisque;
    text-align: center;
    text-decoration: solid;
}

@media screen and (min-width: 1px) and (max-width: 1020px) {
    .footer-container {
        display: grid;
        grid-template-columns: 1fr 1.1fr 0.9fr;
        grid-template-rows: 0.6fr 0.8fr 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
            "left left left"
            "left left left"
            "right right right";
    }

    .left {
        grid-area: left;
        padding-left: 4rem;
        margin-bottom: 2rem;
        width: 90%;
    }

    .right {
        grid-area: right;
        padding-left: 4rem;
        width: 100%;
        padding-bottom: 0rem;
    }

    h4 {
        font-size: 1rem;
        padding-bottom: 1rem;
        color: bisque;
    }

    h3 {
        font-size: 1.2rem;
        padding-bottom: 0.6rem;
    }

}

@media screen and (min-width: 641px) and (max-width:1600px) {
    .footer-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .right {
        height: 100%;
        width: 100%;
        max-width: 100%;
        display: grid;
        padding-left: 3rem;
    }

    .left {
        height: 80%;
        max-width: 100%;
        width: 100%;
        display: grid;
        padding-left: 3rem;
    }

    h4 {
        font-size: 1.1rem;
        padding-bottom: 0.6rem;
        color: bisque;
    }

    h3 {
        font-size: 1.3rem;
        padding-bottom: 0.6rem;
    }

    .location {
        display: flex;
    }
}