.contact-container {  display: grid;
    height:70%;
    width:80%;
    grid-template-columns: 1fr 1.1fr 0.9fr;
    grid-template-rows: 0.6fr 2.2fr 0.3fr;
    gap: 0px 0px;
    box-shadow: 0 0 15px rgb(51, 50, 50);
    background-color: rgb(255, 255, 255);
    grid-auto-flow: row;
    grid-template-areas:
    "contact_header contact_header contact_header"
    "contact_form contact_form contact_logo"
    "contact_form contact_form contact_logo";
  }
.contact_header { 
    grid-area: contact_header;
    border-bottom:solid;
    text-align: center;
  }
.contact_header h2{
        display:inline-block;
  }
.contact_header img{
      display:inline-block;
      margin-top: 2rem;
  }
    
.contact_form { 
  grid-area: contact_form;
  padding-top:2rem;
  padding:1rem;
  }
.contact_form label{
      font-weight: 600;
      display: block;
      text-align: center;
  }
.contact_form input{
      height:2rem;
      margin-top: 0.5rem;
      margin-bottom: 0.3rem;
      box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
      border-radius:10px;        
  }
::placeholder{
      color: rgb(0, 0, 0);
      font-size: 14px;
      font-family: sans-serif;
      letter-spacing: .2px;
      text-align: center;
    }
.contact_form textarea{
      margin-top: 0.5rem;
      margin-bottom: 0.3rem;
      box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
      border-radius:10px;
      text-align: center;
    }

input[type=text] {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      box-sizing: border-box;
      font-size: 18px;
      text-align: center;
    }
textarea{
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      box-sizing: border-box;
      font-size: 18px;
  }
input[type=submit] {
      width: 100%;
      background-color: #45a049;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1rem;
    }
    
input[type=submit]:hover {
      background-color: #40f546;
    }

.contact_logo img { 
  margin-top: 1.2rem;
  grid-area: contact_logo; 
  object-fit: cover;
  width:100%;
  height:105.5%;
  padding-right: 0.4rem;
}
.success_message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding-top:10rem;
}

.success_message p {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.success-icon {
  font-size: 3rem;
  color: green;
}
.success_message img{
  display:none;
}

@media screen and (max-width:1600px) {
  .contact-container {  
    display: grid;
    height:87%;
    width:80%;
  }
  .success_message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top:10rem;
  }
}

@media screen and (max-width:640px) {
  .contact-container {  display: grid;
    height:87%;
    width:80%;
    grid-template-columns: 1fr 1.1fr 0.9fr;
    grid-template-rows: 0.6fr 2.2fr 0.3fr;
    gap: 0px 0px;
    box-shadow: 0 0 15px rgb(51, 50, 50);
    background-color: rgb(255, 255, 255);
    grid-auto-flow: row;
    grid-template-areas:
    "contact_header contact_header contact_header"
    "contact_form contact_form contact_form"
    "contact_form contact_form contact_form";
  }
  .contact_form input{
    height:2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
    border-radius:10px;        
    }
  ::placeholder{
      color: rgb(0, 0, 0);
      font-size: 14px;
      font-family: sans-serif;
      letter-spacing: .2px;
      text-align: center;
    }
  .contact_form textarea{
      margin-top: 0.5rem;
      margin-bottom: 0.3rem;
      box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
      border-radius:10px;
      text-align: center;
    }

  input[type=text] {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      box-sizing: border-box;
      font-size: 18px;
      text-align: center;
    }
  textarea{
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      box-sizing: border-box;
      font-size: 18px;
  }
  input[type=submit] {
      width: 100%;
      background-color: #45a049;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1rem;
    }
    
  input[type=submit]:hover {
      background-color: #40f546;
    }

  .contact_logo img { 
    display:None
}
  .success_message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}      