.services-submenu {
    width: 17rem;
    position: absolute;
    list-style: none;
    height:90px;
    text-align: start;
    justify-content: start;
    margin-bottom: 1rem;
}


.services-submenu li{
    background-color: rgba(3, 3, 3, 0.7);
    cursor: pointer;
    width: 100%;
    padding:0.4rem;
}
.services-submenu li p{
    font-size:1.12rem;
}
.services-submenu.clicked{
    display: none;
}

@media screen and (max-width: 1040px) {
    .services-submenu {
        display: none;       
}
}