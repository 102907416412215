.mask {
    width: 100%;
    height: 935px;
    position: relative;
    background-color: rgba(7, 0, 0, 0.15);

}

.mask::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(7, 0, 0, 0.3);

}

.hero {
    height: 935px;
    width: 100%;
}

.hero .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 65%;
    left: 50%;
    text-align: center;

}

.hero .content h1 {
    color: bisque;
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
    font-weight: bold;
    text-shadow: 2px 2px 2.5px bisque;
    margin-top: 3rem;
    cursor: default;
}


.slide {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translateX(-50%);
    white-space: nowrap;
    transition: ease-in-out 1000ms;
}

.into-img {
    height: 935px;
    width: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;

}

.slide-current {
    opacity: 1;
    transform: translateX(0);
    position: relative;
    top: 0;
    left: 0;
    transition: ease-in-out 1000ms;
}

@media screen and (max-width:1600px) {
    .mask {
        width: 100%;
        height: 635px;
        position: relative;
    }

    .mask::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(7, 0, 0, 0.3);
    }

    .hero {
        height: 635px;
        width: 100%;
    }

    .hero .content {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 65%;
        left: 50%;
        text-align: center;
    }

    .hero .content h1 {
        color: bisque;
        font-size: 3rem;
        padding: 0.6rem 0 1.5rem;
        font-weight: bold;
        text-shadow: 2px 2px 2.5px bisque;
    }

    .hero .content p {
        color: bisque;
        font-size: 1.2rem;
        font-weight: 300;
        text-transform: uppercase;
        font-weight: bold;
    }

    .into-img {
        height: 635px;
        width: 100%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
    }
}

@media screen and (max-width:1100px) {
    .mask {
        width: 100%;
        height: 635px;
        position: relative;
    }

    .mask::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(7, 0, 0, 0.3);
    }

    .hero {
        height: 635px;
        width: 100%;
    }

    .hero .content {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 35%;
        left: 50%;
        text-align: center;
    }

    .hero .content h1 {
        color: bisque;
        font-size: 3rem;
        padding: 0.6rem 0 1.5rem;
        font-weight: bold;
        text-shadow: 2px 2px 2.5px bisque;
    }

    .hero .content p {
        color: bisque;
        font-size: 1rem;
        font-weight: 300;
        text-transform: uppercase;
        font-weight: bold;
    }

    .into-img {
        height: 635px;
        width: 100%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
    }
}

@media screen and (max-width:640px) {
    .hero .content {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 40%;
        left: 50%;
        text-align: center;
    }

    .hero .content h1 {
        font-size: 2.5rem;
    }

    .hero .content p {
        font-size: 1.4rem;
    }
}