.service-main-container5{
    background-image: url('main_background.jpg');
    height: 50rem;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.service-nested-container5 {  display: grid;
    border-style: solid;
    border-width: 1px;
    box-shadow: 10px 7px 5px 2px rgb(51, 50, 50);
    background-color: rgb(255, 254, 254, 0.98);
    height:95%;
    width:80%;
    padding:0.7rem;
    grid-template-columns: 2.2fr 0.1fr;
    grid-template-rows: 0.1fr 1.4fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "Description Description"
      "services1 services1";
  }
.Description5 { grid-area: Description;
    border-bottom: solid 10px;
    text-align: center;
    margin-top: 1rem;
    font-weight: 600;
 }
.services5 { grid-area: services1;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom:solid 10px ;
 }  
 .services5 li{
   padding-top: 1.7rem;
 }
 .services5 li h4{
   color: black;
   font-size: 1.3rem;
   padding-bottom: 0rem;
   text-decoration: underline;
}
 .services5 li p{
   font-family: 'Raleway', sans-serif;
   font-size: 1.1rem;
   cursor: default;
   font-weight: 700;
 }
 .arrow {
   border: solid black;
   border-width: 0 3px 3px 0;
   display: inline-block;
   padding: 7px;
   
 }
 .down {
   transform: rotate(45deg);
   -webkit-transform: rotate(45deg);
 }
  
 @media screen and (max-width:640px) {
    .service-main-container5{
        background-image: url('main_background.jpg');
        height: 65rem;
        display:flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    .Description5 { grid-area: Description;
        border-bottom: solid 10px;
        text-align: center;
        margin-top: 0.3rem;
        font-weight: 600;
     }
     .Description5 p{
     font-size: 1rem;
    }

    .services5 { grid-area: services1;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-bottom:solid 10px ;
     }  
     .services5 li{
        padding-top: 0.8rem;
     }

     .services5 li p{
        font-family: 'Raleway', sans-serif;
        font-size: 1rem;
        font-weight: 700;
     }
 }