.mask2 {
    width: 100%;
    height: 335px;
    position: relative;
}

.mask2::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(7, 0, 0, 0.3);
}

.hero2 {
    height: 335px;
    width: 100%;
}

.hero2 .content2 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 21%;
    left: 50%;
    text-align: center;
}

.hero2 .content2 h1 {
    color: bisque;
    font-size: 3rem;
    padding: 0.6rem 0 1.5rem;
    font-weight: bold;
    text-shadow: 2px 2px 2.5px bisque;
}

.hero2 .content2 p {
    color: bisque;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    font-weight: bold;
}


.slide2 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translateX(-50%);
    white-space: nowrap;
    transition: ease-in-out 1000ms;
}

.into-img2 {
    height: 335px;
    width: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;

}

.slide-current2 {
    opacity: 1;
    transform: translateX(0);
    position: relative;
    top: 0;
    left: 0;
    transition: ease-in-out 1000ms;
}

@media screen and (max-width:1600px) {
    .hero2 .content2 h1 {
        font-size: 2rem;
        margin-top: 5rem;
    }

    .hero2 .content2 p {
        font-size: 1.7rem;
    }

    .into-img2 {
        height: 280px;
        width: 100%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
    }

    .mask2 {
        width: 100%;
        height: 280px;
        position: relative;
    }

    .mask2::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(7, 0, 0, 0.3);
    }

    .hero2 {
        height: 280px;
        width: 100%;
    }
}

@media screen and (max-width:1100px) {
    .hero2 .content2 h1 {
        font-size: 2rem;
        margin-top: 4rem;
    }

    .hero2 .content2 p {
        font-size: 1.7rem;
    }

    .into-img2 {
        height: 280px;
        width: 100%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
    }

    .mask2 {
        width: 100%;
        height: 280px;
        position: relative;
    }

    .mask2::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(7, 0, 0, 0.3);
    }

    .hero2 {
        height: 280px;
        width: 100%;
    }
}

@media screen and (max-width:640px) {
    .hero2 .content2 {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 20%;
        left: 50%;
        text-align: center;
    }

    .hero2 .content2 h1 {
        font-size: 2rem;
        margin-top: 0rem;
    }

    .hero2 .content2 p {
        font-size: 1.4rem;
    }

    .mask2 {
        width: 100%;
        height: 280px;
        position: relative;
    }

    .mask2::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(7, 0, 0, 0.3);

    }

    .hero2 {
        height: 280px;
        width: 100%;
    }
}