.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
    color: bisque;
    font-size: 1.4rem;
    font-weight: 800;
}

.header h2 {
    color: bisque;
}

.header-bg {
    background-color: rgba(3, 3, 3, 0.8);
    height: 3%;
    transition: 0.5s;
    color: bisque;
}

.header-bg h2 {
    display: none;
}

.nav-menu {
    display: flex;
    padding-right: 8rem;
}

.nav-menu li {
    padding: 0 1rem;
}

.nav-menu li p {
    box-shadow: inset 0 0 0 0 rgba(3, 3, 3, 0.4);
    font-size: 1.3rem;
    font-weight: 550;
    color: bisque;
    transition: color .6s ease-in-out, box-shadow .6s ease-in-out;
}

.nav-menu li p:hover {
    box-shadow: inset 20rem 0 0 0 rgba(3, 3, 3, 0.4);
    color: white;
    cursor: pointer;
}

.nav-menu li a {
    box-shadow: inset 0 0 0 0 rgba(3, 3, 3, 0.4);
    font-size: 1.4rem;
    font-weight: 600;
    color: bisque;
    transition: color .6s ease-in-out, box-shadow .6s ease-in-out;
}

.nav-menu li a:hover {
    box-shadow: inset 20rem 0 0 0 rgba(3, 3, 3, 0.9);
    color: white;
}

.hamburger {
    display: none;
    color: bisque;
}

@media screen and (max-width: 640px) {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        position: fixed;
        width: 100%;
        height: 50px;
        z-index: 10;
        color: bisque;
        font-size: 1.4rem;
        font-weight: 800;
    }

    .nav-menu {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 7rem;
        width: 100%;
        height: 100vh;
        background: rgba(252, 237, 219, 0.9);
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -3;
        transition: 0.3s;
    }

    .header h2 {
        display: none;
    }


    .header h1 {
        color: bisque;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-menu li {
        padding-top: 0.3rem;
    }

    .nav-menu li a {
        font-size: 1.5rem;
        color: black;
    }

    .nav-menu li p {
        font-size: 1.5rem;
        color: black;
        margin-top: 0rem;
    }

    .hamburger {
        display: initial;
    }

    .nav-close.active {
        display: none;
    }
}